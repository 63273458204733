
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import DonorFilters from "@/components/DonorFilters.vue";
import { GoogleMap, Marker } from "vue3-google-map";
import TableExplorer from "@/components/TableExplorer.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "roadmap",
  components: {
    StreamBarcodeReader,
  },
  props: {
    widgetClasses: String,
    transportFilter: Number,
  },
  data() {
    return {
      mode: "loading",
      camReadMode: false,
      interval: null,
      position: {
        lat: "",
        lng: "",
      },
      scanned: "",
    };
  },
  methods: {
    acceptInput() {
      ApiService.post("samples", {
        visit_id: this.$route.params.id,
        identifier: this.scanned,
      })
        .then((res) => {
          Swal.fire({
            title: "El precinto se registró con éxito",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.back();
          });
        })
        .catch((error) => {
          Swal.fire({
            text: error.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Cerrar",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    },
    clearScanned() {
      (this.scanned as any) = null;
    },
    setMode(newMode) {
      this.mode = newMode;
      (this.scanned as any) = null;
      setTimeout(() => {
        if (this.mode == "input") {
          let el = document.getElementById("inputElement");
          if (el) {
            el.focus();
          }
        }
      }, 100);
    },
    onError(a) {
      console.log(a);
    },
    onLoad(a) {
      console.log("load");
    },
    onDecode(a) {
      if (this.interval) clearTimeout(this.interval as any);
      this.scanned = a;
      (this.interval as any) = setTimeout(() => {
        if (this.scanned == a) {
          (this.scanned as any) = null;
        }
      }, 5000);
    },

    initLocation() {
      navigator.geolocation.watchPosition(
        (position) => {
          this.position.lat = position.coords.latitude.toString();
          this.position.lng = position.coords.longitude.toString();
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },

  mounted() {
    if (this.$route.params.mode) {
      this.setMode(this.$route.params.mode);
    }
    this.initLocation();
  },

  setup() {
    const router = useRouter();

    return { router };
  },
});
